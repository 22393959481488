import type {Attendee, Context} from './attendee-container-machine';
import {GetAttendee, GetAttendeeQuery, GetAttendeeVariables} from './gql';

/**
 * Attempt to fetch an Attendee's data based on the context provided by the
 * client. Thrown errors will be caught by the state machine.
 * @param context - details provided by the client
 * @returns the Attendee's data if found, otherwise, returns null or an error
 * message
 * @throws when errors returned by GraphQL
 * @throws when no data from the `self` query
 */
export async function fetchAttendee(context: Context): Promise<Attendee> {
  return context.client
    .query<GetAttendee, GetAttendeeVariables>({
      query: GetAttendeeQuery,
      context: {environmentId: context.showId},
    })
    .then((result) => {
      const {error, errors, data} = result;
      // if an error message was returned, throw it
      if (typeof error !== 'undefined') throw error;
      // if there are multiple errors returned, throw the message
      else if (typeof errors !== 'undefined')
        throw new Error(errors.map((e) => e.message).join('  '));
      // if the `self` query results didn't return in the result, throw
      else if (!data.self) throw new Error('No data from fetch');
      // otherwise, return the Attendee data model
      else {
        const result: Attendee = {
          ...data.self.attendee,
          attendeeTags: data.self.attendeeTags.flatMap((tag) => tag) || [],
          avatar: data.self.avatar ?? undefined,
          chatTokens: data.self.chatTokens,
          attendeeType: 'access-code',
        };
        return result;
      }
    });
}
