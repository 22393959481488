import {gql} from '@apollo/client';

export const ApplyGuestStorageOperations = gql`
  mutation ApplyGuestStorageOperations(
    $operations: [GuestStorageUpdateOperation!]!
  ) {
    updateGuestStorage(operations: $operations) {
      ... on IGuest {
        tags
      }
      ... on IError {
        message
      }
    }
  }
`;
