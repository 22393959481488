import {isJSONObject} from '@backstage-components/base';
import type {Attendee, Context} from './attendee-container-machine';
import {
  VerifyOpenLogin,
  VerifyOpenLoginArgs,
  VerifyOpenLoginMutation,
  VerifyOpenLoginVariables,
} from './gql';

/**
 * Verify that an Attendee with the context via the name and email provided.
 * Thrown errors will be caught by the state machine.
 * @returns details about the guest
 * @throws when errors returned by GraphQL
 * @throws when no data from the `verifyOpenLogin` mutation
 */
export async function verifyOpenLogin({
  context,
  ...args
}: VerifyPublicAttendeProps): Promise<Attendee> {
  return context.client
    .mutate<VerifyOpenLogin, VerifyOpenLoginVariables>({
      mutation: VerifyOpenLoginMutation,
      variables: {data: args},
      context: {showId: context.showId},
    })
    .then((result) => {
      const {errors, data} = result;
      // if an error message was returned, throw it
      if (typeof errors !== 'undefined') {
        throw new Error(errors.map((e) => e.message).join('  '));
      } else if (data?.verifyOpenLogin.__typename === 'DataIntegrityError') {
        const {code, message} = data.verifyOpenLogin;
        if (code === 'InvalidEmail') {
          throw new InvalidEmailError(message);
        } else if (code === 'InvalidOpenLogin') {
          throw new InvalidOpenLoginError(message);
        } else {
          throw new Error(`${code} -- ${message}`);
        }
      } else if (data?.verifyOpenLogin.__typename === 'OpenLoginGuest') {
        // return the attendee data
        const {id, name, email, sessionToken, chatTokens, attendeeTags} =
          data.verifyOpenLogin;
        const chatToken = chatTokens[0];
        if (
          typeof chatToken !== 'undefined' &&
          isJSONObject(chatToken) &&
          'token' in chatToken &&
          typeof chatToken.token === 'string'
        ) {
          const {token} = chatToken;
          const result: Attendee = {
            id,
            name,
            email,
            sessionToken,
            chatTokens: [{token}],
            attendeeTags,
            attendeeType: 'open-login',
          };
          return result;
        } else {
          throw new Error('Malformed or missing chat token.');
        }
      } else {
        throw new Error('Unknown response type.');
      }
    });
}

interface VerifyPublicAttendeProps extends VerifyOpenLoginArgs {
  /** Details provided by the browser client */
  context: Context;
}

/**
 * Custom `Error` implementation to distinguish an invalid email address was
 * provided.
 */
export class InvalidEmailError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, InvalidEmailError.prototype);
  }
}

/**
 * Custom `Error` implementation to distinguish the case where name/email
 * authentication was not possible.
 */
export class InvalidOpenLoginError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, InvalidOpenLoginError.prototype);
  }
}
