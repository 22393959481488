import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {assign, fromPromise, raise, setup, type EventObject} from 'xstate';
import {
  readAccessToken,
  removeAccessToken,
  updateAccessToken,
} from './attendee-session-token';
import {fetchAttendee} from './fetch-attendee';
import type {GetAttendee, GuestExternalServiceType} from './gql';
import {
  AccessCodeNotFoundError,
  AccessCodeOverusedError,
  verifyAccessCode,
} from './verify-access-code';
import {
  ComponentNotFoundError,
  EnvironmentNotFoundError,
  GuestExternalVerificationError,
  ModuleNotFoundError,
  verifyGuestExternal,
} from './verify-external-guest';
import {
  InvalidEmailError,
  InvalidOpenLoginError,
  verifyOpenLogin,
} from './verify-open-login';
import {
  InvalidPublicPasscodeError,
  verifyPublicAttendee,
} from './verify-public-attendee';

const FALLBACK_SHOW_ID = '00000000-0000-0000-0000-000000000000';

const readInitialGuest = (context: Context): Promise<AttendeeResult> => {
  const initialAccessToken = readAccessToken(context.showId);
  if (context.showId === FALLBACK_SHOW_ID) {
    return Promise.reject(new Error('No valid show'));
  } else if (initialAccessToken) {
    return fetchAttendee(context);
  } else {
    return Promise.reject(new Error('No stored attendee'));
  }
};

interface VerifyGuestOptions {
  context: Context;
  event: Action;
}

const verifyGuest = (options: VerifyGuestOptions): Promise<AttendeeResult> => {
  const {context, event} = options;
  if (event.type === 'VERIFY') {
    return verifyAccessCode({
      context,
      environmentId: event.meta.showId,
      accessCode: event.meta.accessCode,
    });
  } else if (event.type === 'VERIFY_EXTERNAL') {
    return verifyGuestExternal({
      context,
      environmentId: event.meta.showId,
      email: event.meta.email,
      externalId: event.meta.externalId,
      moduleId: event.meta.moduleId,
      name: event.meta.name,
      serviceType: event.meta.serviceType,
      siteVersionId: event.meta.siteVersionId,
    });
  } else if (event.type === 'VERIFY_PUBLIC') {
    return verifyPublicAttendee({
      context,
      environmentId: event.meta.showId,
      passCode: event.meta.passCode,
      name: event.meta.name,
      moduleId: event.meta.moduleId,
      siteVersionId: event.meta.siteVersionId,
    });
  } else if (event.type === 'VERIFY_OPEN_LOGIN') {
    return verifyOpenLogin({
      context,
      agreementAnswer: event.meta.agreementAnswer,
      agreementText: event.meta.agreementText,
      email: event.meta.email,
      moduleId: event.meta.moduleId,
      name: event.meta.name,
      environmentId: event.meta.showId,
      siteVersionId: event.meta.siteVersionId,
    });
  } else {
    throw new Error(`Unknown event type ${event.type}`);
  }
};

/**
 * State machine managing a guest's authentication informaiton.
 * - `init` indicates an unknown state, the initial session is being evaluated
 * - `idle` indicates an unauthenticated state without errors
 * - `pending` indicates authentication information is being verified
 * - `success` indicates an authenticated state
 * - `failure` indicates an unauthenticated state with errors
 */
export const ContainerMachine = setup({
  types: {} as {
    context: Context;
    events: Action;
    input: Pick<Context, 'client' | 'showId'>;
  },
  actions: {
    clearAttendee: assign(({context, event}) => {
      if (event.type === 'FETCH_FAILURE' || event.type === 'RESET') {
        // Clear from local storage
        removeAccessToken(context.showId);
        // Return `BaseContext`
        const result: BaseContext = {
          about: context.about,
          client: context.client,
          showId: context.showId,
        };
        return result;
      } else {
        return context;
      }
    }),
    updateAttendee: assign(({context, event}) => {
      if (event.type === 'FETCH_SUCCESS' && event.meta.attendee) {
        const attendee = event.meta.attendee;
        const token = attendee.chatTokens[0]?.token ?? '';
        const sessionToken = attendee.sessionToken;
        // Set session token in local storage
        if (typeof sessionToken === 'string') {
          updateAccessToken(context.showId, sessionToken);
        }
        const result: SuccessContext = {
          about: context.about,
          attendee,
          attendeeTags: event.meta.attendee.attendeeTags,
          client: context.client,
          sessionToken,
          showId: context.showId,
          token,
        };
        return result;
      } else {
        return context;
      }
    }),
    updateReason: assign(({context, event}) => {
      if (event.type === 'FETCH_FAILURE') {
        const result: FailureContext = {
          about: context.about,
          client: context.client,
          reason: event.meta.reason,
          showId: context.showId,
        };
        return result;
      } else {
        return context;
      }
    }),
    updateShowId: assign(({context, event}) => {
      if (event.type === 'RESET') {
        const result: BaseContext = {
          about: context.about,
          client: context.client,
          showId: event.meta.showId ?? context.showId,
        };
        return result;
      } else {
        return context;
      }
    }),
    updateVerifyIdentifiers: assign(({context, event}): BaseContext => {
      switch (event.type) {
        case 'VERIFY': // intentional fall through
        case 'VERIFY_EXTERNAL': // intentional fall through
        case 'VERIFY_OPEN_LOGIN': // intentional fall through
        case 'VERIFY_PUBLIC':
          return {
            about: event.meta.about,
            client: context.client,
            showId: event.meta.showId,
          };
        default:
          return context;
      }
    }),
  },
  actors: {
    readInitialGuest: fromPromise<AttendeeResult, Context>(({input}) =>
      readInitialGuest(input)
    ),
    verifyGuest: fromPromise<AttendeeResult, VerifyGuestOptions>(({input}) =>
      verifyGuest(input)
    ),
  },
}).createMachine({
  id: 'AttendeeContainer',
  initial: 'init',
  context: ({input}) => ({client: input.client, showId: input.showId}),
  states: {
    init: {
      invoke: {
        id: 'initialize',
        src: 'readInitialGuest',
        input: ({context}) => context,
        onDone: {
          actions: [
            raise(({event}) => {
              const action: Action = {
                type: 'FETCH_SUCCESS',
                meta: {attendee: event.output},
              };
              return action;
            }),
          ],
        },
        onError: {
          target: 'idle',
        },
      },
      on: {
        FETCH_SUCCESS: {
          target: 'success',
          actions: ['updateAttendee'],
        },
      },
    },
    idle: {
      initial: 'standard',
      states: {
        standard: {},
        logout: {
          entry: ['clearAttendee'],
        },
      },
      on: {
        RESET: {actions: ['clearAttendee', 'updateShowId']},
        VERIFY: {target: 'pending', actions: ['updateVerifyIdentifiers']},
        VERIFY_EXTERNAL: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
        VERIFY_OPEN_LOGIN: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
        VERIFY_PUBLIC: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
      },
    },
    pending: {
      invoke: {
        id: 'perform',
        src: 'verifyGuest',
        input: ({context, event}) => ({context, event}),
        onDone: {
          actions: [
            raise(({event}) => {
              const action: Action = {
                type: 'FETCH_SUCCESS',
                meta: {attendee: event.output},
              };
              return action;
            }),
          ],
        },
        onError: {
          actions: [
            raise(({event}) => {
              const reason =
                event.error instanceof Error
                  ? getReason(event.error)
                  : 'Unable to verify';
              const action: Action = {type: 'FETCH_FAILURE', meta: {reason}};
              return action;
            }),
          ],
        },
      },
      on: {
        FETCH_SUCCESS: {
          target: 'success',
          actions: ['updateAttendee'],
        },
        FETCH_FAILURE: {
          target: 'failure',
          actions: ['updateReason'],
        },
      },
    },
    success: {
      on: {
        RESET: {target: 'idle.logout', actions: ['updateShowId']},
        VERIFY: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
        VERIFY_EXTERNAL: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
        VERIFY_OPEN_LOGIN: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
        VERIFY_PUBLIC: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
      },
    },
    failure: {
      entry: ['clearAttendee'],
      on: {
        RESET: {target: 'idle', actions: ['updateShowId']},
        VERIFY: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
        VERIFY_EXTERNAL: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
        VERIFY_OPEN_LOGIN: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
        VERIFY_PUBLIC: {
          target: 'pending',
          actions: ['updateVerifyIdentifiers'],
        },
      },
    },
  },
});

const getReason = (error: Error): string | undefined => {
  switch (error.constructor) {
    case AccessCodeOverusedError: {
      return 'Access Code has been used too many times';
    }
    case AccessCodeNotFoundError: {
      // `undefined` here is a marker in the consuming modules for the error to
      // use the fallback or default message
      return undefined;
    }
    case ComponentNotFoundError: // intentional fall through
    case ModuleNotFoundError:
      return 'Module could not be validated';
    case GuestExternalVerificationError:
      return 'Guest could not be stored';
    case InvalidEmailError:
      return 'Email address could not be validated';
    case InvalidOpenLoginError:
      return 'Credentials could not be provisioned';
    case InvalidPublicPasscodeError: {
      return error.message;
    }
    case EnvironmentNotFoundError:
      return 'Environment could not be validated';
    default: {
      return `Unexpected error: ${error.name}`;
    }
  }
};

/**
 * `AttendeeResult` is expressed this way to ensure the return types of the
 * related functions remain compatible, if they become incompatible the
 * `AttendeeResult` type will become `never` which will cause type errors.
 */
type AttendeeResult = Awaited<ReturnType<typeof fetchAttendee>> &
  Awaited<ReturnType<typeof verifyAccessCode>> &
  Awaited<ReturnType<typeof verifyGuestExternal>> &
  Awaited<ReturnType<typeof verifyOpenLogin>> &
  Awaited<ReturnType<typeof verifyPublicAttendee>>;

interface BaseContext {
  about?: string;
  client: ApolloClient<NormalizedCacheObject>;
  showId: string;
}

interface FailureContext extends BaseContext {
  reason?: string;
}

interface SuccessContext extends BaseContext {
  attendee: Attendee;
  attendeeTags: string[];
  token?: string;
  sessionToken?: string;
}

export type Context = BaseContext | FailureContext | SuccessContext;

type Action =
  | Event<'RESET', {showId?: string}>
  | Event<
      'VERIFY',
      {
        about?: string;
        showId: string;
        siteVersionId?: string;
        accessCode: string;
      }
    >
  | Event<
      'VERIFY_EXTERNAL',
      {
        about?: string;
        showId: string;
        siteVersionId?: string;
        moduleId: string;
        name: string;
        email?: string;
        externalId: string;
        serviceType: GuestExternalServiceType;
      }
    >
  | Event<
      'VERIFY_PUBLIC',
      {
        about?: string;
        showId: string;
        siteVersionId?: string;
        passCode: string;
        moduleId: string;
        name: string;
      }
    >
  | Event<
      'VERIFY_OPEN_LOGIN',
      {
        about?: string;
        showId: string;
        siteVersionId?: string;
        agreementAnswer: boolean;
        agreementText: string;
        email?: string;
        moduleId: string;
        name: string;
      }
    >
  | Event<'FETCH_FAILURE', {reason?: string}>
  | Event<'FETCH_SUCCESS', {attendee: Attendee}>;

/**
 * An `Event` with a specific shape of data (`meta` key) and `type`.
 */
interface Event<
  Kind extends string,
  Data extends Record<string, unknown> = Record<string, never>,
> extends EventObject {
  /** @inheritdoc */
  type: Kind;
  /**
   * The shape of Data included with the event, if any.
   */
  meta: Data;
}

/**
 * `verifyAccessCode` represents the response after a guest has been
 * authenticated.
 */
type AccessCode = Exclude<GetAttendee['self'], null>;

/**
 * Add the `attendeeType` value to the `attendee` details in the `AccessCode`
 * response type to distinguish between the various authentication methods.
 */
type AttendeeDetails = AccessCode['attendee'] & {
  attendeeType:
    | 'access-code'
    | 'open-login'
    | 'pass-code'
    | GuestExternalServiceType;
};

/** The properties pertaining to an Attendee */
export interface PublicAttendeeModel {
  /** URL for the avatar image of the attendee/guest */
  avatar?: string;
  sessionToken?: string;
  attendeeTags: string[];
  chatTokens: AccessCode['chatTokens'];
}

/** The properties pertaining to an Attendee */
export type AttendeeModel = AttendeeDetails & PublicAttendeeModel;

/** The Attendee data model, or possibly null if the attendee wasn't found  */
export type Attendee = AttendeeModel;
