import type {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {
  GuestStorageInstructionSchema,
  useShowInstructions,
} from '@backstage-components/base';
import {assertNever} from '@backstage/utils/type-helpers';
import {useSubscription} from 'observable-hooks';
import {useEffect, useMemo, useReducer, type Reducer} from 'react';
import {filter, map} from 'rxjs';
import {
  ApplyGuestStorageOperations,
  type ApplyGuestStorageOperationsMutation,
  type ApplyGuestStorageOperationsMutationVariables,
} from '../gql';

type GuestStorageValue = string | number | boolean | string[];

/** The type of the in memory representation of Guest Storage */
export type GuestStorage = Record<string, GuestStorageValue>;

/** Provides functions to get data out of `GuestStorage` */
export interface GuestStorageProxy {
  /**
   * Retrieve a list from `GuestStorage`, if `key` doesn't exist or isn't a list
   * then `undefined`.
   */
  getList: (key: string) => string[] | undefined;
}

/**
 * Manages the instructions for guest storage.
 * - Listens for modification commands via the instruction system and dispatchs
 *   those commands to the API.
 * - Listends for changes to guest storage and broadcasts those to the local
 *   broker
 */
export function useGuestStorage<ApolloCache = NormalizedCacheObject>(
  client: ApolloClient<ApolloCache>,
  environmentId: string,
  initialValue: GuestStorage
): GuestStorageProxy {
  const {broadcast, observable} = useShowInstructions(
    GuestStorageInstructionSchema
  );
  const [state, dispatch] = useReducer(guestStorageReducer, initialValue);
  // If the `initialValue` changes reset the stored value
  useEffect(() => {
    dispatch({op: '::RESET', storage: initialValue});
  }, [initialValue]);
  // Broadcast an update anytime the `state` value changes
  useEffect(() => {
    broadcast({type: 'GuestStorage:on-update', meta: state});
  }, [broadcast, state]);
  // Map the `GuestStorage:` prefixed instructions into `GuestStorageOperation`
  const operations = useMemo(
    () =>
      observable.pipe(
        map((instruction): GuestStorageOperation | null => {
          switch (instruction.type) {
            case 'GuestStorage:list:add':
              return {
                key: instruction.meta.key,
                op: 'LIST_ADD',
                value: instruction.meta.value,
              };
            case 'GuestStorage:list:remove':
              return {
                key: instruction.meta.key,
                op: 'LIST_REMOVE',
                value: instruction.meta.value,
              };
            case 'GuestStorage:on-update':
              return null;
            case 'GuestStorage:tag:add':
              return {
                key: 'tags',
                op: 'LIST_ADD',
                value: instruction.meta.value,
              };
            case 'GuestStorage:tag:remove':
              return {
                key: 'tags',
                op: 'LIST_REMOVE',
                value: instruction.meta.value,
              };
            default:
              assertNever(instruction);
          }
        }),
        filter(
          (operation): operation is GuestStorageOperation => operation !== null
        )
      ),
    [observable]
  );
  // Send each of the `GuestStorageOperation` received to the API and to the
  // state reducer
  useSubscription(operations, {
    next: (operation) => {
      dispatch(operation);
      sendCommands(client, environmentId, [operation]);
    },
  });
  return {
    getList: (key) => {
      const value = state[key];
      if (Array.isArray(value)) {
        return value;
      } else {
        return undefined;
      }
    },
  };
}

/**
 * Represents an individual Guest Storage operation which can be sent to the API
 */
type GuestStorageOperation = Exclude<
  ApplyGuestStorageOperationsMutationVariables['operations'],
  Array<unknown>
>;

/** Send `GuestStorageOperation` instances to the API */
async function sendCommands<ApolloCache = NormalizedCacheObject>(
  client: ApolloClient<ApolloCache>,
  environmentId: string,
  commands: GuestStorageOperation[]
): Promise<GuestStorage> {
  const response = await client.mutate<
    ApplyGuestStorageOperationsMutation,
    ApplyGuestStorageOperationsMutationVariables
  >({
    mutation: ApplyGuestStorageOperations,
    variables: {operations: commands},
    context: {environmentId},
  });
  const {data} = response;
  if (data === null || typeof data === 'undefined') {
    return {};
  } else if ('message' in data.updateGuestStorage) {
    // Ideally this would return a previous state
    return {};
  } else {
    return {tags: data.updateGuestStorage.tags};
  }
}

type GuestStorageAction =
  | GuestStorageOperation
  | {op: '::RESET'; storage: GuestStorage};

/**
 * Combine `GuestStorageOperation` with current state to get the new
 * `GuestStorage` representation
 */
const guestStorageReducer: Reducer<GuestStorage, GuestStorageAction> = (
  draft,
  action
) => {
  if (action.op === '::RESET') {
    return action.storage;
  } else if (action.op === 'LIST_ADD' || action.op === 'LIST_REMOVE') {
    const existing = draft[action.key] ?? [];
    const list = Array.isArray(existing)
      ? existing
      : typeof existing === 'string'
        ? [existing]
        : [`${existing}`];
    const s = new Set(list);
    if (action.op === 'LIST_ADD') {
      s.add(action.value);
    } else if (action.op === 'LIST_REMOVE') {
      s.delete(action.value);
    }
    return Object.assign({}, draft, {[action.key]: Array.from(s)});
  } else {
    assertNever(action.op);
  }
};
