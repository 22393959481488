import type {Attendee, Context} from './attendee-container-machine';
import type {
  GuestExternalServiceType,
  VerifyGuestExternal,
  VerifyGuestExternalVariables,
} from './gql';
import {VerifyGuestExternalMutation} from './gql';

/**
 * Passes data to the API representing a guest verified through an external
 * system and creating an association to `externalId` for `serviceType`.
 * @throws ComponentNotFoundError if there is an error with the module where
 * the component doesn't exist
 * @throws ModuleNotFoundError if there is an error validating the `moduleId`
 * @throws EnvironmentNotFoundError if there is an error validating the
 * `environmentId`
 * @throws StorageError if there is an error saving the record
 */
export async function verifyGuestExternal(
  args: VerifyExternalGuestArgs
): Promise<Attendee> {
  const {context, moduleId, siteVersionId = null} = args;
  const guestData: VerifyGuestExternalVariables['data'] = {
    email: args.email,
    environmentId: args.environmentId,
    externalId: args.externalId,
    name: args.name,
    serviceType: args.serviceType,
  };
  return context.client
    .mutate<VerifyGuestExternal, VerifyGuestExternalVariables>({
      mutation: VerifyGuestExternalMutation,
      variables: {data: guestData, moduleId, siteVersionId},
      context: {showId: context.showId},
    })
    .then((result) => {
      const {errors, data} = result;
      // if an error message was returned, throw it
      if (typeof errors !== 'undefined') {
        throw new Error(errors.map((e) => e.message).join('  '));
      }
      // if the response indicates an error throw it
      else if (data?.verifyExternalGuest.__typename === 'DataIntegrityError') {
        const {code, message} = data.verifyExternalGuest;
        switch (code) {
          case 'ComponentNotFound':
            throw new ComponentNotFoundError(message);
          case 'ModuleNotFound':
            throw new ModuleNotFoundError(message);
          case 'ShowNotFound':
            throw new EnvironmentNotFoundError(message);
          case 'Storage':
            throw new GuestExternalVerificationError(message);
          default:
            throw new Error(`${code} -- ${message}`);
        }
      }
      // if no verification data was returned, throw an error
      else if (
        typeof data?.verifyExternalGuest.sessionToken === 'undefined' ||
        typeof data?.verifyExternalGuest.attendee === 'undefined' ||
        data.verifyExternalGuest.sessionToken === null ||
        data.verifyExternalGuest.attendee === null
      ) {
        throw new Error('No data from verify.');
      }
      // otherwise, return the attendee's data
      else {
        const result: Attendee = {
          ...data.verifyExternalGuest.attendee,
          sessionToken: data.verifyExternalGuest.sessionToken,
          attendeeTags: data.verifyExternalGuest.tags,
          avatar: data.verifyExternalGuest.avatar ?? undefined,
          chatTokens: data.verifyExternalGuest.chatTokens,
          attendeeType: args.serviceType,
        };
        return result;
      }
    });
}

type VerifyExternalGuestArgs = {
  /** Details provided by the browser client */
  context: Context;
  /** The id of the show to verify */
  environmentId: string;
  /** The published version of the site to verify against */
  siteVersionId?: string;
  /** The identifier from the external service */
  externalId: string;
  /** The name associated with the external guest */
  name: string;
  /** The email associated with the external guest */
  email?: string;
  /** The identifier of the module that is requesting the verification */
  moduleId: string;
  /** The service from which the guest was verified */
  serviceType: GuestExternalServiceType;
};

/** Indicates an unknown compoment was associated with the module id provided */
export class ComponentNotFoundError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, ComponentNotFoundError.prototype);
  }
}

/** Indicates the module id provided could not be located */
export class ModuleNotFoundError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, ModuleNotFoundError.prototype);
  }
}

/** Indicates no environment with the given id exists */
export class EnvironmentNotFoundError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, EnvironmentNotFoundError.prototype);
  }
}

/** Indicates an issue storing the record in the data store */
export class GuestExternalVerificationError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, GuestExternalVerificationError.prototype);
  }
}
