import {storage} from '@backstage-components/base';
import Cookie from 'js-cookie';

const sessionTokenKey = (showId: string): string =>
  `lcd-ac/${showId.slice(0, 8)}/access-token`;

/**
 * Reads the attendee session token from cookies or local storage. An attendee
 * session token Cookie is preferred over the value in local storage, meaning if
 * an attendee session token exists in both cookies and local storage the value
 * in cookies will be returned.
 */
export function readAccessToken(showId: unknown): string | null {
  const initialToken = Cookie.get('session-token');
  if (typeof showId === 'string') {
    return initialToken ?? storage.getItem(sessionTokenKey(showId));
  } else {
    return initialToken ?? null;
  }
}

/**
 * Remove the attendee session token from both cookie storage an local storage.
 */
export function removeAccessToken(showId: string): void {
  Cookie.remove('session-token', {
    domain: location.hostname,
    Paritioned: true,
    sameSite: 'None',
    secure: true,
  });
  storage.removeItem(sessionTokenKey(showId));
}

/**
 * Set the given attendee session token (`token`) in both cookie storage and
 * local storage.
 */
export function updateAccessToken(showId: string, token?: string | null): void {
  if (typeof token === 'string') {
    Cookie.set('session-token', token, {
      domain: location.hostname,
      expires: 1, // expires in one day
      Paritioned: true,
      sameSite: 'None',
      secure: true,
    });
    storage.setItem(sessionTokenKey(showId), token);
  }
}
